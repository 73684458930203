import React from "react"
import styled from "styled-components"

import SEO from "../../../components/shared/SEO"
import Card from "../card/LayoutA"
import Inner from "../../../dsm/layout/Inner"
import Layout from "../../../dsm/layout"
import PageHeader from "../../../layouts/PageHeader"
import CategoryNavigation from "./categoriesNavigation"
import { CreateLocalLink } from "../../../utils"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ItemsArchive = ({ pageContext }) => {
  const { lightSkin } = getFlexiOptionsBranding()

  const {
    name,
    items,
    slug,
    link,
    pathSub,
    description,
    ancestorLink,
    ancestorName,
  } = pageContext

  const { nodes } = items

  // Items Heading
  const heading =
    process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL.charAt(0).toUpperCase() +
    process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL.slice(1)

  const cleanName = name.replace(/&amp;/g, "&")
  let cleanAncestorName
  if (pathSub) {
    cleanAncestorName = ancestorName.replace(/&amp;/g, "&")
  }

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  // Breadcrumbs
  const crumbs = [
    { pathname: "/", crumbLabel: "Home" },
    {
      pathname: `/${process.env.GATSBY_ITEMS_CATALOGUE_SLUG_PLURAL}/`,
      crumbLabel: heading,
    },
  ]
  if (pathSub) {
    crumbs.push({
      pathname: CreateLocalLink(ancestorLink).replace(
        "item",
        process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
      ),
      crumbLabel: `${cleanAncestorName}`,
    })
    crumbs.push({
      pathname: pathSub,
      crumbLabel: `${cleanName} ${heading}`,
    })
  } else {
    crumbs.push({
      pathname: CreateLocalLink(link).replace(
        "item",
        process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
      ),
      crumbLabel: `${cleanName} ${heading}`,
    })
  }

  return (
    <Layout>
      <SEO
        title={`${cleanName} | ${heading}`}
        description={`${cleanName} ${heading}`}
        keywords={[`${cleanName} ${heading}`]}
      />
      <PageHeader title={cleanName} crumbs={crumbs} />
      <Wrapper lightSkin={lightSkin} mainBgColor={mainBgColor}>
        <Inner>
          <div>
            <h4>
              Our {heading} in "{cleanName}"
            </h4>
            <p dangerouslySetInnerHTML={{ __html: description }} />
            {nodes && nodes.map((item) => <Card key={item.id} item={item} />)}
          </div>
          <div className="sidebar">
            <div className="categories-widget">
              <h4>Select a Category</h4>
              <CategoryNavigation active={slug} />
            </div>
          </div>
        </Inner>
      </Wrapper>
    </Layout>
  )
}
export default ItemsArchive

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? mainBgColor
      : lightSkin
      ? theme.colors.tusk[200]
      : theme.colors.midnight[500]};
  .categories-widget {
    position: sticky;
    top: 20px;
  }
  .inner {
    display: grid;
    grid-template-columns: 2fr 300px;
    grid-gap: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  h4 {
    font-weight: 400;
    margin: 0;
  }
  @media (max-width: ${({ theme }) => theme.breakLarge}) {
    .inner {
      grid-template-columns: 1fr;
    }
  }
`
