import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Icon from "../../../dsm/icons/icon"
import FluidImage from "../../../components/Images/FluidImage"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"
import {
  CreateLocalLink,
  getInverseColour,
  getColorLuminance,
} from "../../../utils"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const ItemCardLayoutA = ({ item }) => {
  const { link, title, excerpt, featuredImage } = item
  const { lightSkin, shape, primaryColor } = getFlexiOptionsBranding()

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Card
      to={CreateLocalLink(link).replace(
        "item",
        process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
      )}
      shape={shape}
      lightSkin={lightSkin}
      mainBgColor={mainBgColor}
      primaryColor={primaryColor}
    >
      <div className="body">
        <FluidImage image={featuredImage} withFallback />
        <div className="content">
          <h3>{title}</h3>
          <div dangerouslySetInnerHTML={{ __html: excerpt }} />
          <div className="fake-btn">
            More{" "}
            <Icon name="arrow-right" color={getInverseColour(primaryColor)} />
          </div>
        </div>
      </div>
    </Card>
  )
}
export default ItemCardLayoutA

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Card = styled(Link)`
  text-align: left;
  background: ${({ theme, lightSkin, mainBgColor }) =>
    mainBgColor
      ? getColorLuminance(mainBgColor, 0.3)
      : lightSkin
      ? theme.colors.tusk[100]
      : theme.colors.midnight[300]};
  display: grid;
  grid-template-rows: auto 1fr auto;
  border: none;
  box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 15px;
  margin: 40px 0;
  h3 {
    margin: 10px 0;
    line-height: 1.1;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[300] : theme.colors.white[100]};
  }
  img {
    border-radius: 8px;
  }
  .body {
    display: grid;
    grid-template-columns: 350px 1fr;
    grid-gap: 20px;
  }
  .content {
    padding: 15px;
    p {
      margin: 0;
      color: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[300]};
      opacity: 0.8;
    }
  }
  .fake-btn {
    display: grid;
    grid-template-columns: auto auto;
    background: ${({ primaryColor }) => primaryColor};
    color: ${({ primaryColor }) => getInverseColour(primaryColor)};
    opacity: 1;
    border-radius: ${({ shape }) => shape};
    padding: 10px 15px;
    margin-top: 12px;
    width: fit-content;
    grid-gap: 8px;
    align-items: center;
    font-weight: 600;
    text-transform: uppercase;
  }
  &:hover {
    border: none;
    transform: translateY(-4px);
  }
  @media (max-width: ${({ theme }) => theme.breakSmall}) {
    .body {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
`
