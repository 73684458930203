import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"

import {
  CreateLocalLink,
  getInverseColour,
  getColorLuminance,
} from "../../../utils"
import getFlexiOptionsLayouts from "../../../options/getFlexiOptionsLayouts"
import getFlexiOptionsBranding from "../../../options/getFlexiOptionsBranding"

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const CategoryNavigation = ({ active }) => {
  const { primaryColor, secondaryColor, lightSkin } = getFlexiOptionsBranding()

  const {
    wpgraphql: {
      itemCategories: { nodes },
    },
  } = useStaticQuery(ITEMS_CATEGORIES)

  const {
    websiteSkeleton: { mainBgColor },
  } = getFlexiOptionsLayouts()

  return (
    <Wrapper
      primaryColor={primaryColor}
      lightSkin={lightSkin}
      mainBgColor={mainBgColor}
    >
      {nodes.map((cat, i) =>
        cat.count > 0 ? (
          cat.children.nodes.length === 0 ? (
            <Link
              to={CreateLocalLink(cat.link).replace(
                "item",
                process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
              )}
              key={i}
              className={`${cat.slug === active ? "active" : ""}`}
            >
              {cat.name}
            </Link>
          ) : (
            <DropMenu
              active={active}
              key={i}
              category={cat}
              lightSkin={lightSkin}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
            />
          )
        ) : null
      )}
    </Wrapper>
  )
}
export default CategoryNavigation

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const Wrapper = styled.div`
  margin-top: 40px;
  a {
    display: block;
    border: none;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
    background: ${({ theme, lightSkin, mainBgColor }) =>
      mainBgColor
        ? getColorLuminance(mainBgColor, 0.4)
        : lightSkin
        ? theme.colors.tusk[100]
        : theme.colors.midnight[100]};
    box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.05);
    padding: 12px 15px;
    margin: 2px 0;
    border-radius: 2px;
    text-transform: capitalize;
    &:hover {
      cursor: pointer;
      border: none;
      color: ${({ primaryColor }) => getInverseColour(primaryColor)};
      background-color: ${({ primaryColor }) => primaryColor};
    }
    &.active {
      color: ${({ primaryColor }) => getInverseColour(primaryColor)};
      background-color: ${({ primaryColor }) => primaryColor};
    }
  }
`

///////////////////////////////////////
// 🛠 Component
///////////////////////////////////////
const DropMenu = ({
  active,
  category,
  lightSkin,
  primaryColor,
  secondaryColor,
}) => {
  const activeParent =
    category.children.nodes.filter((c) => c.slug === active).length > 0

  const [open, setOpen] = useState(category.slug === active || activeParent)

  return (
    <DropMenuWrapper
      lightSkin={lightSkin}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      <div
        onClick={() => setOpen(!open)}
        className={`trigger ${open ? "open" : "closed"}`}
      >
        <span dangerouslySetInnerHTML={{ __html: category.name }} />
        <div className="icon">
          <span />
          <span />
        </div>
      </div>
      <div className={`sub-menu ${open ? "open" : "closed"}`}>
        <Link
          to={CreateLocalLink(category.link).replace(
            "item",
            process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
          )}
        >
          All <span dangerouslySetInnerHTML={{ __html: category.name }} />
        </Link>
        {category.children.nodes.map((subCat) => (
          <Link
            to={`${CreateLocalLink(category.link).replace(
              "item",
              process.env.GATSBY_ITEMS_CATALOGUE_SLUG_SINGULAR
            )}${subCat.slug}/`}
          >
            <span dangerouslySetInnerHTML={{ __html: subCat.name }} />
          </Link>
        ))}
      </div>
    </DropMenuWrapper>
  )
}

///////////////////////////////////////
// 💅 Styles
///////////////////////////////////////
const DropMenuWrapper = styled.div`
  .trigger {
    display: grid;
    grid-template-columns: 1fr 24px;
    border: none;
    color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.midnight[100] : theme.colors.tusk[400]};
    background-color: ${({ theme, lightSkin }) =>
      lightSkin ? theme.colors.tusk[100] : theme.colors.midnight[100]};
    box-shadow: 0 10px 23px 0 rgba(0, 0, 0, 0.05);
    padding: 12px 15px;
    margin: 2px 0;
    border-radius: 2px;
    .icon {
      transition: all 200ms ease-in-out;
      position: relative;
      span {
        position: absolute;
        top: 11px;
        left: 5px;
        background: ${({ secondaryColor }) => secondaryColor};
        width: 15px;
        height: 2px;
        border-radius: 4px;
        display: block;
        transition: all 200ms ease-in-out;
        &:last-child {
          transform: rotate(90deg);
        }
      }
    }
    &:hover {
      cursor: pointer;
      border: none;
      color: ${({ primaryColor }) => getInverseColour(primaryColor)};
      background-color: ${({ primaryColor }) => primaryColor};
      #icon {
        fill: ${({ primaryColor }) => getInverseColour(primaryColor)};
      }
    }
    &.open {
      color: ${({ primaryColor }) => primaryColor};
      .icon span:last-child {
        transform: rotate(0deg);
      }
      &:hover {
        color: ${({ primaryColor }) => getInverseColour(primaryColor)};
        background-color: ${({ primaryColor }) => primaryColor};
        #icon {
          fill: ${({ primaryColor }) => getInverseColour(primaryColor)};
        }
      }
    }
  }
  .sub-menu {
    transition: all 200ms ease-in-out;
    &.closed {
      opacity: 0;
      display: none;
    }
    &.open {
      opacity: 1;
      display: block;
    }
    a {
      background-color: ${({ theme, lightSkin }) =>
        lightSkin ? theme.colors.tusk[200] : theme.colors.midnight[200]};
      margin: 0;
      padding: 12px 15px 12px 25px;
      border-bottom: 1px solid
        ${({ theme, lightSkin }) =>
          lightSkin ? theme.colors.tusk[300] : theme.colors.midnight[300]};
      &:hover {
        cursor: pointer;
        color: ${({ primaryColor }) => getInverseColour(primaryColor)};
        background-color: ${({ primaryColor }) => primaryColor};
      }
      &[aria-current="page"] {
        color: ${({ primaryColor }) => getInverseColour(primaryColor)};
        background-color: ${({ primaryColor }) => primaryColor};
      }
      &:last-child {
        border: none;
      }
    }
  }
`

///////////////////////////////////////
// 🔎 Queries
///////////////////////////////////////
const ITEMS_CATEGORIES = graphql`
  {
    wpgraphql {
      itemCategories {
        nodes {
          link
          slug
          name
          count
          children {
            nodes {
              link
              slug
              name
            }
          }
        }
      }
    }
  }
`
